<template>
	<div
		class="router-view-wrapper"
		v-if="show_recipients"
	>
		<div class="data-wrapper">
			<q-item tag="label">
				<q-item-section>
					<q-item-label>Use a <strong>single</strong> criteria to select recipients:</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="is_single_criteria" :val="true" color="primary" />
				</q-item-section>
			</q-item>
			<q-item tag="label">
				<q-item-section>
					<q-item-label>Use <strong>multiple</strong> criteria to select recipients:</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="is_single_criteria" :val="false" color="primary" />
				</q-item-section>
			</q-item>
			<q-item v-if="!this.is_single_criteria" tag="label">
				<q-item-section>
					<q-item-label>Send this message to people who meet <strong>ALL</strong> of this criteria?</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="is_multiple_criteria_all" :val="true" color="primary" />
				</q-item-section>
			</q-item>
			<q-item v-if="!this.is_single_criteria" tag="label">
				<q-item-section>
					<q-item-label>Send this message to people who meet <strong>ANY</strong> of this criteria?</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="is_multiple_criteria_all" :val="false" color="primary" />
				</q-item-section>
			</q-item>
			<div
				v-for="(option, index) in include_recipient_options"
				:key="index"
			>
				<RecipientOptions
					:index="index"
					:recipient_options="include_recipient_options"
				/>
			</div>
			<div v-if="!is_single_criteria">
				<q-btn
					class="padding"
					flat
					icon="add"
					color="primary"
					label="Add new filter criteria"
					@click="addNewField()"
				/>
			</div>
			<q-item tag="label">
				<q-item-section>
					<q-item-label>Do you need to exclude any recipients?</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle v-model="is_exclude_recipients" />
				</q-item-section>
			</q-item>

			<q-item v-if="this.is_exclude_recipients" tag="label">
				<q-item-section>
					<q-item-label>Exclude people who meet <strong>ALL</strong> of these criteria:</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="is_exclude_recipients_all" :val="true" color="primary" />
				</q-item-section>
			</q-item>
			<q-item v-if="this.is_exclude_recipients" tag="label">
				<q-item-section>
					<q-item-label>Exclude people who meet <strong>ANY</strong> of these criteria:</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="is_exclude_recipients_all" :val="false" color="primary" />
				</q-item-section>
			</q-item>

			<div v-if="is_exclude_recipients">
				<div
					v-for="(option, index) in exclude_recipient_options"
					:key="index"
				>
					<RecipientOptions
						:exclude="true"
						:index="index"
						:recipient_options="exclude_recipient_options"
					/>
				</div>
			</div>

			<div v-if="is_exclude_recipients">
				<q-btn
					class="padding"
					flat
					color="primary"
					icon="add"
					label="Add new filter criteria"
					@click="addNewCriteriaExcluded()"
				/>
			</div>
			<div v-if="has_teams_permission && (scenario_type === 'broadcast' || scenario_type === 'sequence')">
				<MicrosoftTeamsRecipient />
			</div>
			<div v-if="has_slack_permission && (scenario_type === 'broadcast' || scenario_type === 'sequence')">
				<SlackRecipient />
			</div>
			<div v-if="has_pwa_permission && (scenario_type === 'broadcast' || scenario_type === 'sequence')">
				<PWARecipient />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RecipientOptions from "@/components/message_forms/recipients_tab/RecipientOptions.vue";
import MicrosoftTeamsRecipient from "@/components/message_forms/recipients_tab/MicrosoftTeamsRecipient.vue";
import SlackRecipient from "@/components/message_forms/recipients_tab/SlackRecipient.vue";
import PWARecipient from "@/components/message_forms/recipients_tab/PWARecipient.vue";

export default {
	name: "MessageFormRecipients",
	components: {
		RecipientOptions,
		MicrosoftTeamsRecipient,
		SlackRecipient,
		PWARecipient,
	},
	data() {
		return {
			recipients_list: [],
			recipients: {},
			show_recipients: false,
			has_teams_permission: false,
			has_slack_permission: false,
			has_pwa_permission: false
		};
	},
	computed: {
		...mapGetters([
			"include_recipient_options",
			"exclude_recipient_options",
			"errors",
			"errors_per_tab",
			"logged_user",
			"current_CG",
			'scenario_type',
		]),
		is_single_criteria: {
			get(){
				return this.$store.getters["is_single_criteria"]
			},
			set(val){
				this.resetIncludeRecipientsList();
				this.recipients_list = [];
				this.recipients.include_criteria = [];
				this.recipients.include_operator = "";
				if (!val) {
					this.recipients.include_operator = "AND";
				}
				this.updateIsSingleCriteria(val)
			}
		},
		is_multiple_criteria_all: {
			get(){
				if (this.$store.getters["is_multiple_criteria_all"]) {
					this.recipients.include_operator = "AND";
				} else {
					this.recipients.include_operator = "OR";
				}
				return this.$store.getters["is_multiple_criteria_all"]
			},
			set(val){
				if (val) {
					this.recipients.include_operator = "AND";
				} else {
					this.recipients.include_operator = "OR";
				}
				this.updateIsMultipleCriteriaAll(val)
			}
		},
		is_exclude_recipients: {
			get(){
				return this.$store.getters["is_exclude_recipients"]
			},
			set(val){
				if (!val){
					this.resetExcludeRecipientsList();
					this.recipients.exclude_criteria = [];
					this.recipients.exclude_operator = "";
				}
				this.updateIsExcludeRecipients(val)
			}
		},
		is_exclude_recipients_all: {
			get(){
				if (this.$store.getters["is_exclude_recipients_all"]) {
					this.recipients.exclude_operator = "AND";
				} else {
					this.recipients.exclude_operator = "OR";
				}

				return this.$store.getters["is_exclude_recipients_all"]
			},
			set(val){
				if (val) {
					this.recipients.exclude_operator = "AND";
				} else {
					this.recipients.exclude_operator = "OR";
				}
				this.updateIsExcludeRecipientsAll(val)
			}
		},
	},
	methods: {
		...mapActions([
			"updateIncludeRecipientOptions",
			"updateExcludeRecipientOptions",
			"resetIncludeRecipientsList",
			"resetExcludeRecipientsList",
			"updateIsSingleCriteria",
			"updateIsMultipleCriteriaAll",
			"updateIsExcludeRecipients",
			"updateIsExcludeRecipientsAll"

		]),
		getMessage() {
			const errors = this.$store.getters.errors_per_tab['Recipients']
			for (let error of errors){
				this.$q.notify({
					timeout: 6700,
					message: error,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
		addNewField() {
			this.updateIncludeRecipientOptions();
		},
		addNewCriteriaExcluded() {
			this.updateExcludeRecipientOptions();
		},
	},
	created() {
		this.show_recipients =
			this.logged_user.customer_permission !== "SENDER" ||
			(this.logged_user.customer_permission === "SENDER" &&
				this.current_CG.root_customer_group_permissions.includes(
					"Allow Senders to edit recipients"
				));
		this.getMessage()

		// Set integration permissions
		const permissions = this.current_CG.root_customer_group_permissions
		this.has_teams_permission = permissions.includes('Use Microsoft Teams');
		this.has_slack_permission = permissions.includes('Use Slack');
		this.has_pwa_permission = permissions.includes('Use Flutter PWA');
	}
};
</script>
