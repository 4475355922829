<template>
	<div v-if="list_webhooks.length">
		<q-item tag="label">
			<q-item-section>
				<q-item-label>Use Slack</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle v-model="use_slack"/>
			</q-item-section>
		</q-item>
		<q-select
			v-if="use_slack"
			class="padding"
			multiple
			use-input
			input-debounce="0"
			v-model="selected_slack_webhooks"
			:options="list_webhooks"
			name="Select webhooks"
			label="Select webhooks"
			:option-label="(item) => item.webhook_name"
		/>
	</div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SlackApi from '@/services/api/Slack';

export default {
	name: 'SlackRecipient',
	data(){
		return{
			list_webhooks: [],
		}
	},
	computed: {
		...mapGetters(["current_CG"]),
		use_slack: {
			get() {
				return this.$store.getters.use_slack
			},
			set(value) {
				if (!value){
					this.updateSelectedSlackWebhooks([]);
				}
				this.updateUseSlack(value);
			}
		},
		selected_slack_webhooks: {
			get() {
				return this.$store.getters.selected_slack_webhooks
			},
			set(value) {
				this.updateSelectedSlackWebhooks(value);
			}
		}
	},
	methods:{
		...mapActions(['updateUseSlack', 'updateSelectedSlackWebhooks']),
	},
	async created(){
		this.list_webhooks = await SlackApi.listSlackWebhooks(this.current_CG.customer_group.id, true)
	}
}
</script>
