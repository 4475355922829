<template>
	<div>
		<q-item>
			<q-select
				class="full-width"
				v-model="recipient_option"
				:options="recipient_types"
				label="Select Recipients"
			/>
			<q-btn
				v-if="recipient_options.length > 1"
				flat
				round
				color="primary"
				@click="removeCriteria(index)"
				icon="delete"
			/>
		</q-item>
		<q-item v-if="recipient_option && recipient_option.type =='group' && recipient_option.label != 'Everyone'">
			<u class="row items-center"><a @click="selectAllGroups()">Select all Datasets</a></u>
		</q-item>

		<q-item
			v-if="recipient_option && recipient_option.type == 'group' && recipient_option.label != 'Everyone'"
		>
			<q-select
				v-model="option_value"
				:options="recipient_option.options"
				multiple
				use-chips
				:option-label="(item) => item.name"
				label="Select options"
				class="full-width"
			/>
		</q-item>
		<q-item v-if="recipient_option && recipient_option.type == 'user'">
			<q-select
				v-model="option_value"
				:options="filter_options"
				multiple
				use-input
				use-chips
				input-debounce="0"
				:option-label="(item) => item.address_string"
				label="Select options"
				class="full-width"
				@filter="filterFn"
			/>
		</q-item>

		<q-item v-if="recipient_option && recipient_option.type == 'field'">
			<q-select
				v-model="option_value"
				:options="recipient_option.options"
				multiple
				use-chips
				:option-label="(item) => item.value"
				label="Select options"
				class="full-width"
			/>
		</q-item>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	name: "RecipientOptions",
	props:{
		index: Number,
		recipient_options: Array,
		exclude: Boolean
	},
	data(){
		return{
			dataset_options: [],
			filter_options: [],
		}
	},
	computed:{
		...mapGetters([
			'recipient_types',
			'include_recipient_options',
			'exclude_recipient_options',
		]),
		recipient_option:{
			get(){
				if(!this.exclude){
					return this.include_recipient_options[this.index]['recipient_type'];
				}else{
					return this.exclude_recipient_options[this.index]['recipient_type'];
				}
			},
			set(val){
				if(!this.exclude){
					this.updateIncludeRecipientOptions({"index": this.index, "value": val});
				}else{
					this.updateExcludeRecipientOptions({"index": this.index, "value": val});
				}
			}
		},
		option_value:{
			get(){
				if(!this.exclude){
					return this.include_recipient_options[this.index]['recipient_value'];
				}else{
					return this.exclude_recipient_options[this.index]['recipient_value'];
				}
			},
			set(val){
				if(!this.exclude){
					this.updateIncludeRecipientOptionValue({"index": this.index, "value": val})
				}else{
					this.updateExcludeRecipientOptionValue({"index": this.index, "value": val})
				}
			}
		}
	},
	methods:{
		...mapActions([
			'updateIncludeRecipientOptions',
			'updateIncludeRecipientOptionValue',
			'removeIncludeRecipientOption',
			'removeIncludeRecipientOptionValue',
			'updateExcludeRecipientOptions',
			'updateExcludeRecipientOptionValue',
			'removeExcludeRecipientOption',
			'removeExcludeRecipientOptionValue',
		]),
		removeCriteria(){
			if(!this.exclude){
				this.removeIncludeRecipientOption(this.index);
			}else{
				this.removeExcludeRecipientOption(this.index);
			}
		},
		selectAllGroups(){
			if(!this.exclude){
				this.updateIncludeRecipientOptionValue({"index": this.index, "value": this.include_recipient_options[this.index].recipient_type.options})
			}else{
				this.updateExcludeRecipientOptionValue({"index": this.index, "value": this.exclude_recipient_options[this.index].recipient_type.options})
			}
		},
		filterFn (val, update, type) {
			if (val === '') {
				update(() => {
					this.filter_options = this.recipient_option.options
				})
				return
			}

			update(() => {
				const needle = val.toLowerCase()
				this.filter_options = this.recipient_option.options.filter(({address_string}) => address_string.toLowerCase().indexOf(needle) > -1)
			})
		}
	},
}
</script>
