<template>
	<div>
		<q-item tag="label">
			<q-item-section>
				<q-item-label>Use PWA</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle v-model="use_pwa"/>
			</q-item-section>
		</q-item>
	</div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
	name: 'PWARecipient',
	computed: {
		use_pwa: {
			get() {
				return this.$store.getters.use_pwa
			},
			set(value) {
				this.updateUsePWA(value)
			}
		}
	},
	methods: {
		...mapActions(['updateUsePWA'])
	},
}
</script>
